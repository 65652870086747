<template>
  <div>
    <div class="white-bg">

      <v-carousel
          v-if="showAddOrg"
          height="600px"
          hide-delimiter-background
          :show-arrows=false
          ref="carousel"
          class="createNonResidental"
      >
        <v-carousel-item
        >
      <div class="create-org-wrap" :class="{ 'dn': !showNewCommerce }">
        <div class="add-org">
          <img src="@/assets/images/money-smile.svg" alt="" />
          <div class="title">
            Добавить <br />
            организацию
          </div>
          <form>
            <input type="text"
                   placeholder="Название организации"
                   v-model="formData.first_name"

            />
            <input type="text" placeholder="Деятельность" v-model="formData.buisness_line"/>
            <input type="text" placeholder="Этаж" v-model="formData.floor" />

            <div class="btn-row">
              <button type="button" class="back-btn" @click="toggleAddOrg">Назад</button>
              <button type="button" @click.stop="$refs.carousel.next()" class="next-btn">Далее</button>

            </div>
          </form>
        </div>
      </div>
        </v-carousel-item >
        <v-carousel-item class="mt-12">
          <div class="create-org-wrap" :class="{ 'dn': !showNewCommerce }">
            <div class="add-org">
              <div class="title">
                Добавить <br />
                организацию
              </div>
              <form>
                <input type="text" placeholder="Имя" v-model="formData.first_name" />
                <input type="text" placeholder="Фамилия" v-model="formData.last_name"/>
                <input type="text" v-model="formData.phone" v-mask="'+ 7 (###) ###-##-##'" placeholder="Телефон">
                <input type="text" placeholder="Номер лицевого счета" v-mask="'#########'" v-model="formData.personal_account"/>
                <div class="btn-row">
                  <button type="button" class="back-btn" @click="$refs.carousel.prev()">Назад</button>
                  <button type="button" class="next-btn" @click="addNotResidential">Добавить</button>
                </div>
              </form>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>

      <div :class="{ 'dn': showNewCommerce }">
        <div v-for="block in blocks" :key="`block-${block.id}`" class="block">
          <div class="block-title">Блок {{ block.number }}</div>
          <div class="add-entrance" @click="toggleAddOrg(block.id)">
            + Добавить организацию
          </div>
        </div>
<!--        <div class="create-block">-->
<!--          <div class="block-title grey">Блок 3</div>-->
<!--          <div class="create-block-btn">Создать</div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getBlocksApi } from '@/api/companies';
import { mapState } from 'vuex';
import Calendar from "../../views/Calendar";
import {
  required,
  email,
  minLength,
  maxLength, between
} from 'vuelidate/lib/validators'

export default {
  components: {Calendar},
  props: {
    type: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showEditEntrance: false,
    showAddOrg: false,
    blocks: [],
    phone:'',
    formData:{
      building_type:4,
      block: 0,
      floor: 0,
      personal_account:"",
      name:'',
      buisness_line:"",
      first_name:"",
      last_name:"",
    },
  }),
  validations() {
    return {
      formData: {
        first_name: { required },
        last_name: { required },
        personal_account: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(9),
        },
        phone: {
          length: notRequiredOrLength(19),
        },
      },
    };
  },
  computed: {
    ...mapState(['loading']),
    showNewCommerce() {
      return this.blocks.length === 0 && !this.loading.some((l) => l === 'companies.getBlocksApi') || this.showAddOrg;
    },
  },
  methods: {
    toggleEditEntrance() {
      this.showEditEntrance = !this.showEditEntrance;
    },
    toggleDeleteEntrance() {
      this.showDeleteEntrance = !this.showDeleteEntrance;
      this.showEditEntrance = !this.showEditEntrance;
    },
    toggleAddOrg(id) {
      this.showAddOrg = !this.showAddOrg;
      this.formData.block=id;
    },
    async getBlocks() {
      const res = await getBlocksApi();
      this.blocks = res.data;
    },
    init() {
      this.getBlocks();
    },
    addOrg() {
      console.log('add org');
    },
    async addNotResidential(){
      console.log(this.formData);
    }
  },
  created() {
    this.init();
  },
}
</script>

<style>

</style>
